<template>
	<div class="pay">
		<div class="inner">
			<div class="labelView">
				<div class="txt" @click="$router.push('/')">
					{{i18n('02')}}
				</div>
				<div class="gang">
					/
				</div>
				<div class="txt" @click="$router.push('/cart')">
					{{i18n('29')}}
				</div>
				<div class="gang">
					/
				</div>
				<div class="txt">
					{{i18n('116')}}
				</div>
				<div class="gang">
					/
				</div>
				<div class="txt">
					{{i18n('31')}}
				</div>
			</div>
			<div class="title">
				<div class="left">
					{{i18n('210')}}
				</div>
				<div class="right">
					{{i18n('211')}}：<div class="">
						<span>
							₱
						</span> {{orderData.total_money}}
					</div>
				</div>
			</div>
			<div class="payType">
				<div class="list">
					<div :class="pay_method==1?'item itemAc':'item'" @click="pay_methodChange(1)">
						<!-- <div class="top">
							<span style="font-size: 12px;"> ₱</span>{{balanceNumber}}
						</div> -->
						<div class="top">
							<img src="@/assets/df.png" alt="" class="df" style="width: 30px;height: 30px;">
						</div>
						<div class="bot">
							{{i18n('339')}}
						</div>
					</div>
					<div :class="pay_method==2?'item itemAc':'item'" @click="pay_methodChange(2)">
						<!-- <div class="top">
							<span style="font-size: 12px;"> ₱</span>{{balanceNumber}}
						</div> -->
						<div class="top">
							<img src="@/assets/df.png" alt="" class="df" style="width: 30px;height: 30px;">
						</div>
						<div class="bot">
							{{i18n('340')}}
						</div>
					</div>
					<div :class="pay_method==3?'item itemAc':'item'" @click="pay_methodChange(3)">
						<div class="top">
							<img src="@/assets/df.png" alt="" class="df" style="width: 30px;height: 30px;">
						</div>
						<div class="bot">
							{{i18n('341')}}
						</div>
					</div>
					<div :class="pay_method==4?'item itemAc':'item'" @click="pay_methodChange(4)">
						<div class="top">
							<img src="@/assets/df.png" alt="" class="df" style="width: 30px;height: 30px;">
						</div>
						<div class="bot">
							{{i18n('342')}}
						</div>
					</div>
				</div>
				<div class="but" @click="sub">
					{{i18n('213')}}
				</div>
			</div>

		</div>
		<foot></foot>
	</div>
</template>

<script>
	// 1 余额支付 发物流 2 余额支付 到店自取 3 到店自取付现金  4 货到付款发物流
	import {
		getCookies
	} from "@/utils/cookies.js"
	import {
		submit,payagain
	} from "@/api/order.js"
	import {
		getBalance
	} from "@/api/user.js"

	import foot from "@/components/topBot/footer.vue"
	export default {
		name: "pay",
		components: {
			foot
		},
		data() {
			return {
				balanceNumber: 0,
				pay_method: 1,
				orderData: JSON.parse(getCookies('orderData')),
				total_money:JSON.parse(getCookies('orderData')).total_money
			}
		},
		methods: {
			async sub() {
				console.log(this.orderData)
				if (this.orderData.set_id) {
					this.payagain()
					return false
				}
				console.log(10000)
				this.orderData.pay_method = this.pay_method
				let data = false
				data = await submit(this.orderData)
				
				setTimeout(()=>{
					this.$store.commit('cart/removeSetItems')
					this.$store.dispatch('cart/cartAll')
				},1000)
				// if (data.pay_method == 4 || data.pay_method == 3) {
					//前往支付结果页面
					this.$router.push('/orderResult?set_id=' + data.data.id)
				// } else if (data.pay_method == 2) {
				// 	//前往微信扫码

				// } else {
				// 	let res = JSON.parse(data.pay_result)
				// 	let url = ''
				// 	if (data.pay_method != 8) {
				// 		let data = res.results
				// 		url = data.pay_url || data.api_url
				// 	} else {
				// 		url = res.redirectUrl
				// 	}
				// 	window.open(url)
					
				// 	this.$confirm(this.i18n('214'), this.i18n('31'), {
				// 		confirmButtonText: this.i18n('215'),
				// 		cancelButtonText: this.i18n('216'),
				// 		type: 'info',
				// 		confirmButtonClass: "ele-qd"
				// 	}).then(() => {
				// 		//前往支付结果页面
				// 		this.$router.push('/orderResult?set_id=' + data.data.id)
				// 	}).catch(() => {
				// 		//前往订单详情重新支付
				// 		this.$router.push('/orderResult?set_id=' + data.data.id)	

				// 	});
				// }

			},
			async payagain() {
				this.orderData.pay_method = this.pay_method
				console.log("重新支付")
				let data = await payagain(this.orderData)
				console.log()
				if (data.set.pay_method == 4 || data.set.pay_method == 3) {
					//前往支付结果页面
					this.$router.push('/orderResult?set_id=' + data.set.id)
				} else if (data.set.pay_method == 2) {
					//前往微信扫码

				} else {
					let res = JSON.parse(data.pay_res)
					let url = ''
					console.log("data")
					console.log(data)
					if (data.set.pay_method != 8) {
						let data2 = res.results
						console.log('data2222')
						console.log(data2)
						url = data2.pay_url || data2.api_url
					} else {
						url = res.redirectUrl
					}
					console.log('url')
					console.log(url)
					window.open(url)
					this.$store.commit('cart/removeSetItems')
					this.$store.dispatch('cart/cartAll')
					this.$confirm(this.i18n('214'), this.i18n('31'), {
						confirmButtonText: this.i18n('215'),
						cancelButtonText: this.i18n('216'),
						type: 'info',
						confirmButtonClass: "ele-qd"
					}).then(() => {
						//前往支付结果页面
						this.$router.push('/orderResult?set_id=' + this.orderData.set_id)
					}).catch(() => {
						//前往订单详情重新支付
						this.$router.push('/orderResult?set_id=' + this.orderData.set_id)

					});
				}
			},
			pay_methodChange(type) {
				if(type == 1 || type == 4){
					this.orderData.total_money = this.total_money
				}else if(type==2 || type == 3){
					this.orderData.total_money = this.total_money-this.orderData.freight
				}
				if ((type == 1 || type == 2) && this.balanceNumber < this.orderData.total_money) {
					this.$message.error(this.i18n('217'))
					return false
				}
				this.pay_method = type
			},
		},
		async created() {
			let {
				balance
			} = await getBalance()
			this.balanceNumber = balance
			if (this.$route.query.set_id) {
				this.orderData = {
					set_id: Number(this.$route.query.set_id),
					pay_method: 0,
					source: 7,
					total_money: this.$route.query.pay_price
				}
			}
			this.$store.commit('user/setUserInfo', {
				balance: this.balanceNumber
			})
			this.$store.commit('user/setClassifyShow', false)
		},
		mounted() {
			console.log(this.orderData)
		}
	};
</script>

<style scoped>
	.but {
		width: 160px;
		height: 44px;
		background: #56AB7B;
		border-radius: 24px;
		margin: 0 auto;
		font-family: PingFangSC-Regular;
		font-size: 18px;
		color: #F5F5F5;
		text-align: center;
		font-weight: 400;
		line-height: 44px;
		margin-top: 50px;
		cursor: pointer;
	}

	.payType {
		width: 875px;
		height: 380px;
		background: #FFFFFF;
		border-radius: 12px;
		padding: 25px;
		box-sizing: border-box;
		margin: 0 auto;
	}

	.list {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.list .item:nth-child(4n) {
		margin-right: 0;
	}

	.list .item {
		width: 198px;
		height: 79px;
		background: #FFFFFF;
		border: 1px solid rgba(217, 217, 217, 1);
		border-radius: 6px;
		margin-right: 8px;
		margin-bottom: 24px;
		cursor: pointer;
	}

	.list .itemAc {
		border: 1px solid #56AB7B;
	}

	.list .item .top {
		height: 37px;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 10px;
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #000000;
		font-weight: 500;
	}

	.list .item .bot {
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #4E5969;
		font-weight: 400;
		text-align: center;
	}

	.title {
		width: 875px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 21px;
	}

	.title .left {
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #1D2129;
		line-height: 22px;
		font-weight: 500;
	}

	.title .right {
		display: flex;
		align-items: center;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #86909C;
		text-align: right;
		font-weight: 400;
	}

	.title .right div {
		font-family: PingFangSC-Medium;
		font-size: 18px;
		color: #F23725;
		text-align: right;
		line-height: 28px;
		font-weight: 500;
	}

	.title .right div span {
		font-size: 12px;
	}

	.labelView {
		padding-left: 50px;
		height: 70px;
		display: flex;
		align-items: center;
	}

	.labelView .txt {
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #4E5969;
		line-height: 22px;
		font-weight: 400;
	}

	.labelView .gang {
		font-size: 12px;
		padding: 0 6px;
		opacity: .6;
		line-height: 22px;
	}

	.inner {
		display: flex;
		flex-direction: column;
		height: 586px;
		flex: 1;
		background: #F5F5F5;
		border-radius: 0 0 20px 20px;
	}

	.pay {
		flex: 1;
		height: 100%;
		overflow-y: scroll;
	}
</style>
